import {
    getSelectedProductIdFromActionInput,
    getSelectedVariant,
    IProductInventoryInformation,
    // mapProductInventoryInformation,
    SelectedVariantInput
} from '@msdyn365-commerce-modules/retail-actions';
import { mapProductInventoryInformation } from '../utilities/inventory/product-inventory-utils';

import {
    CacheType,
    createObservableDataAction,
    IAction,
    IActionContext,
    IActionInput,
    IAny,
    ICreateActionContext,
    IGeneric
} from '@msdyn365-commerce/core';
import { ProductWarehouseInventoryAvailability, ReleasedProductType } from '@msdyn365-commerce/retail-proxy';
import {
    getAttributeValuesAsync,
    getEstimatedAvailabilityAsync
} from '@msdyn365-commerce/retail-proxy/dist/DataActions/ProductsDataActions.g';
import { ProductAvailableQuantity } from '@msdyn365-commerce/retail-proxy/dist/Entities/CommerceTypes.g';
/**
 * Input class for the getProductAvailabilitiesForSelectedVariant Data Action
 */
export class ProductAvailabilitiesForSelectedVariantInput implements IActionInput {
    public productId: number;
    public channelId: number;

    constructor(productId: number | string, channelId: number) {
        this.productId = typeof productId === 'string' ? +productId : productId;
        this.channelId = channelId;
    }

    public getCacheKey = () => `ProductAvailabilitiesForSelectedVariant`;
    public getCacheObjectType = () => 'ProductAvailabilities';
    public dataCacheType = (): CacheType => 'none';
}

/**
 * createInput method for the getProductAvailabilitiesForSelectedVariant data action.
 * @param inputData The input data passed to the createInput method
 */
export const createProductAvailabilitiesForSelectedVariantInput = (
    inputData: ICreateActionContext<IGeneric<IAny>>
): ProductAvailabilitiesForSelectedVariantInput => {
    const productId = getSelectedProductIdFromActionInput(inputData);

    if (productId) {
        return new ProductAvailabilitiesForSelectedVariantInput(+productId, +inputData.requestContext.apiSettings.channelId);
    } else {
        throw new Error('Unable to create ProductAvailabilitiesForSelectedVariantInput, no productId found on module config or query');
    }
};

/**
 * The action method for the getProductAvailabilitiesForSelectedVariant data action
 */
// tslint:disable-next-line: max-func-body-length
export async function getProductAvailabilitiesForSelectedVariantAction(
    input: ProductAvailabilitiesForSelectedVariantInput,
    ctx: IActionContext
): Promise<IProductInventoryInformation[] | undefined> {
    const selectedVariantInput = new SelectedVariantInput(input.productId, input.channelId);
    /* Upgraded to 10.0.16 - START */
    // tslint:disable-next-line:prefer-type-cast
    const PRODUCTASSERVICE = 2 as ReleasedProductType.Service;
    /* Upgraded to 10.0.16 - END */
    return getSelectedVariant(selectedVariantInput, ctx)
        .then(async productResult => {
            if (
                productResult &&
                /* Upgraded to 10.0.16 - START - Add following condition */
                // As we just have master products only so will comment this condition
                productResult.ItemTypeValue !== PRODUCTASSERVICE /* && productResult.ProductTypeValue !== ProductType.Master */
                /* Upgraded to 10.0.16 - END */
            ) {
                try {
                    /* VSI Customization - START - 09/04/21 */
                    // Check if product is vendorShipped we dont need getEstimatedAvailabilityAsync call as we'll just display instock with maxCartLineQty limit
                    const {
                        requestContext: {
                            app: {
                                config: { maxQuantityForCartLineItem, fulfillmentAttributeName, fulfillmentAttributeTextValue }
                            }
                        }
                    } = ctx;

                    let productInventoryInformation: IProductInventoryInformation[];

                    // Make vendorShip products in stock by default
                    const productAttributes = await getAttributeValuesAsync(
                        { callerContext: ctx },
                        input.productId,
                        input.channelId,
                        ctx.requestContext.apiSettings.catalogId
                    );
                    const isVendorShipAttribute = productAttributes.find(attribute => {
                        const attributeName = attribute.Name && attribute.Name.trim().toLowerCase();
                        return attributeName === 'isvendershipproduct';
                    });

                    if (
                        isVendorShipAttribute &&
                        isVendorShipAttribute.TextValue &&
                        isVendorShipAttribute.TextValue.toLowerCase() === 'yes'
                    ) {
                        const vendorShipProductInventory: IProductInventoryInformation[] = [];

                        vendorShipProductInventory.push({
                            IsProductAvailable: true,
                            ProductAvailableQuantity: {
                                AvailableQuantity: maxQuantityForCartLineItem,
                                ProductId: productResult.RecordId
                            }
                        });

                        return vendorShipProductInventory;
                    } else {
                        const attributeName = fulfillmentAttributeName
                            ? fulfillmentAttributeName
                                  .toString()
                                  .toLowerCase()
                                  .trim()
                            : 'fulfillmenttype';
                        const attributeTextValue = fulfillmentAttributeTextValue
                            ? fulfillmentAttributeTextValue
                                  .toString()
                                  .toLowerCase()
                                  .trim()
                            : 'plants';

                        const fulfillmentTypeAttribute =
                            productAttributes &&
                            productAttributes.find(attribute => {
                                return attribute?.Name?.toLowerCase() === attributeName;
                            });
                        const isPlantFulfillment =
                            fulfillmentTypeAttribute && fulfillmentTypeAttribute.TextValue?.toLowerCase() === attributeTextValue;
                        const isPlantCategory = isPlantFulfillment ? true : false;
                        /* VSI Customization - END - 09/04/21 */
                        return getEstimatedAvailabilityAsync(
                            { callerContext: ctx },
                            {
                                ProductIds: [productResult.RecordId],
                                DefaultWarehouseOnly: !isPlantCategory,
                                FilterByChannelFulfillmentGroup: isPlantCategory
                            }
                        ).then(async response => {
                            if (
                                response &&
                                response.ProductWarehouseInventoryAvailabilities &&
                                response.ProductWarehouseInventoryAvailabilities.length
                            ) {
                                if (isPlantCategory) {
                                    // Get ProductWarehouseInventoryAvailabilities as a sum of all warehouse inventory and use it
                                    const accumulatedAvailability = getAccumulatedAvailability(
                                        ctx,
                                        response.ProductWarehouseInventoryAvailabilities
                                    );

                                    productInventoryInformation = mapProductInventoryInformation(ctx, accumulatedAvailability);
                                } else {
                                    productInventoryInformation = mapProductInventoryInformation(
                                        ctx,
                                        response.ProductWarehouseInventoryAvailabilities
                                    );
                                }
                            }
                            /* VSI Customization - END */
                            return productInventoryInformation;
                        });
                    }
                } catch (error) {
                    ctx.trace((error as Error).message);
                    ctx.telemetry.exception(error as Error);
                    ctx.telemetry.debug(`[getEstimatedAvailabilityAsync]Error executing action`);
                    throw new Error('[getEstimatedAvailabilityAsync]Error executing action');
                }
            }
            return [];
        })
        .catch((error: Error) => {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-argument -- can't assign type to the error variable.
            ctx.telemetry.exception(error);
            ctx.telemetry.debug('[getPriceForSelectedVariantAction]Error executing action');
            return undefined;
        });
}

/**
 * The function that maps a ProductWareHouse object into a ProductAvailabilityQuantity
 */
export function mergeProductWarehouseToProductAvailabities(
    productsWarehouseInventory: ProductWarehouseInventoryAvailability[]
): ProductAvailableQuantity[] {
    const productAvailable: ProductAvailableQuantity[] = [];
    if (productsWarehouseInventory && productsWarehouseInventory.length > 0) {
        for (const product of productsWarehouseInventory) {
            if (product.TotalAvailable !== undefined && product.ProductId !== undefined) {
                productAvailable.push({ ProductId: product.ProductId, AvailableQuantity: product.TotalAvailable });
            }
        }
    }
    return productAvailable;
}

/**
 * The function that adds all warehouse's PhysicalAvailable for plants Category and return that ProductWarehouseInventoryAvailabilities
 */
const getAccumulatedAvailability = (
    ctx: IActionContext,
    ProductWarehouseInventoryAvailabilities: ProductWarehouseInventoryAvailability[]
) => {
    // Just check inventory in warehouseIdsForFulfillment
    const {
        requestContext: {
            app: {
                config: { warehouseIdsForFulfillment }
            }
        }
    } = ctx;

    let accumulatedProductCount = 0;
    const fulfillmentStores: string[] | undefined = warehouseIdsForFulfillment; // ['S011', 'S040']
    // We'll just check the PhysicalAvailable for plants fulfillment
    ProductWarehouseInventoryAvailabilities.filter(availability => {
        // First check if it is one of the warehouseIdsForFulfillment, only then add inventory
        return (
            fulfillmentStores &&
            fulfillmentStores.find(store => {
                return availability.InventLocationId && availability.InventLocationId.toLowerCase() === store.toLowerCase();
            })
        );
    }).map(availability => {
        if (availability.PhysicalAvailable) {
            accumulatedProductCount += availability.PhysicalAvailable;
        }
    });
    // Now just create an object with this product availability info and returns it
    return mapWarehouseAvailabilityForAccumulatedCount(ctx, ProductWarehouseInventoryAvailabilities, accumulatedProductCount);
};

/**
 * The function that creates an object of type ProductWarehouseInventoryAvailability with new provided accumulatedProductCount
 * and return it as an array of type
 */
const mapWarehouseAvailabilityForAccumulatedCount = (
    ctx: IActionContext,
    ProductWarehouseInventoryAvailabilities: ProductWarehouseInventoryAvailability[],
    accumulatedProductCount: number
): ProductWarehouseInventoryAvailability[] => {
    // Use labels from app.settings for both in stock & out of stock cases
    const {
        requestContext: {
            app: {
                config: { inStockCode, inStockLabel, outOfStockCode, outOfStockLabel }
            }
        }
    } = ctx;

    const productAvailability = ProductWarehouseInventoryAvailabilities[0];

    return [
        {
            ProductId: productAvailability.ProductId,
            DataAreaId: productAvailability.DataAreaId,
            PhysicalInventory: 0,
            TotalAvailable: accumulatedProductCount,
            TotalAvailableInventoryLevelLabel: accumulatedProductCount > 0 ? inStockLabel : outOfStockCode,
            TotalAvailableInventoryLevelCode: accumulatedProductCount > 0 ? inStockCode : outOfStockLabel,
            PhysicalAvailable: accumulatedProductCount,
            PhysicalAvailableInventoryLevelLabel: accumulatedProductCount > 0 ? inStockLabel : outOfStockLabel,
            PhysicalAvailableInventoryLevelCode: accumulatedProductCount > 0 ? inStockCode : outOfStockCode
        }
    ];
};
/*** The complete getProductAvailabilitiesForSelectedVariant data action
 * Get the currently selected variant via the getSelectedVariant data action, and
 * then gets the availabilities for the variant via the getProductAvailabilities RetailServer API
 ****/
export default createObservableDataAction({
    id: '@msdyn365-commerce-modules/retail-actions/get-product-availabilities-for-selected-variant',
    action: <IAction<IProductInventoryInformation[] | null>>getProductAvailabilitiesForSelectedVariantAction,
    input: createProductAvailabilitiesForSelectedVariantInput
});
