import {
    ActiveCartProductsInput,
    buildCacheKey,
    getActiveCartProductsAction,
    IProductInventoryInformation
    // mapProductInventoryInformation
} from '@msdyn365-commerce-modules/retail-actions';
import { mapProductInventoryInformation } from '../utilities/inventory/product-inventory-utils';

import {
    CacheType,
    createObservableDataAction,
    IAction,
    IActionContext,
    IActionInput,
    IAny,
    ICommerceApiSettings,
    ICreateActionContext,
    IGeneric
} from '@msdyn365-commerce/core';
import { getCartState } from '@msdyn365-commerce/global-state';
import {
    CartLine,
    ChannelDeliveryOptionConfiguration,
    FeatureState,
    ProductSearchCriteria,
    ProductWarehouse,
    ProductWarehouseInventoryInformation,
    SimpleProduct
} from '@msdyn365-commerce/retail-proxy';
import { getOrgUnitConfigurationAsync } from '@msdyn365-commerce/retail-proxy/dist/DataActions/OrgUnitsDataActions.g';
import {
    getEstimatedAvailabilityAsync,
    getEstimatedProductWarehouseAvailabilityAsync,
    searchByCriteriaAsync
} from '@msdyn365-commerce/retail-proxy/dist/DataActions/ProductsDataActions.g';
import { getChannelDeliveryOptionConfigurationAsync } from '@msdyn365-commerce/retail-proxy/dist/DataActions/StoreOperationsDataActions.g';
import { ProductWarehouseInventoryAvailability } from '@msdyn365-commerce/retail-proxy/dist/Entities/CommerceTypes.g';
import { FeatureStateInput, getFeatureStateAction } from './get-feature-state.override.action';
import { unique } from './utilities';
import { getByIdsAsync } from '@msdyn365-commerce/retail-proxy/dist/DataActions/ProductsDataActions.g';
import {
    ArrayExtensions,
    QueryResultSettingsProxy
} from '@msdyn365-commerce-modules/retail-actions';

/**
 * Input class for availabilites for items in cart
 */
export class ProductAvailabilitiesForCartLineItems implements IActionInput {
    private apiSettings: ICommerceApiSettings;

    constructor(apiSettings: ICommerceApiSettings) {
        this.apiSettings = apiSettings;
    }

    public getCacheKey = () => buildCacheKey(`ActiveCartLineItemsAvailability`, this.apiSettings);
    public getCacheObjectType = () => 'ActiveCartLineItemsAvailability';
    public dataCacheType = (): CacheType => 'none';
}

const createInput = (inputData: ICreateActionContext<IGeneric<IAny>>) => {
    return new ProductAvailabilitiesForCartLineItems(inputData.requestContext.apiSettings);
};

/**
 * Calls the Retail API to get the product availabilites for items in the cart
 */
/* Upgraded to 10.0.20 - START */
/**
 * Calls the Retail Feature State API and returns a list of feature with isEnabled flag.
 */
const getDeliveryMode = (
    cartLine: CartLine,
    featureSate: boolean = false,
    channelDeliveryOptionConfig: ChannelDeliveryOptionConfiguration,
    pickupDeliveryMode?: string
) => {
    if (!featureSate) {
        return cartLine.DeliveryMode === pickupDeliveryMode;
    }
    return (
        cartLine.DeliveryMode ===
        channelDeliveryOptionConfig?.PickupDeliveryModeCodes?.find(deliveryMode => deliveryMode === cartLine.DeliveryMode)
    );
};

async function getSimpleProducts(prodIds:number[], input: ProductAvailabilitiesForCartLineItems, ctx: IActionContext): Promise<SimpleProduct[]> {
    const simpleProductsData = ArrayExtensions.validValues(
        await getByIdsAsync(
            {
                callerContext: ctx,
                queryResultSettings: QueryResultSettingsProxy.getPagingFromInputDataOrDefaultValue(ctx)
            },
            ctx.requestContext.apiSettings.channelId,
            prodIds,
            null,
            ctx.requestContext.apiSettings.catalogId ?? 0
        )
    );
    return simpleProductsData;
}

/* Upgraded to 10.0.20 - END */
export async function getAvailabilitiesForCartLineItems(
    input: ProductAvailabilitiesForCartLineItems,
    ctx: IActionContext
): Promise<IProductInventoryInformation[]> {
    // If no input is provided fail out
    if (!input) {
        throw new Error('[getAvailabilitiesForCartLineItems]No valid Input was provided, failing');
    }
    const shippingItems: CartLine[] = [];
    const bopisItems: CartLine[] = [];

    let productAvailabilities: IProductInventoryInformation[] = [];
    const multiplePickupStoreSwitchName = 'Dynamics.AX.Application.RetailMultiplePickupDeliveryModeFeature';
    let channelDeliveryOptionConfig: any;

    const cartState = await getCartState(ctx);
    const cart = cartState.cart;
    const channelConfiguration = await getOrgUnitConfigurationAsync({ callerContext: ctx });
    const products = await getActiveCartProductsAction(new ActiveCartProductsInput(), ctx);
    /* Upgraded to 10.0.20 - START */
    /**
     * Calls the Retail Feature State API and returns a list of feature with isEnabled flag.
     */
    async function getFeatureState(context: IActionContext): Promise<FeatureState[]> {
        return getFeatureStateAction(new FeatureStateInput(), context);
    }
    const featureState = await getFeatureState(ctx);
    const retailMultiplePickUpOptionEnabled = featureState?.find(item => item.Name === multiplePickupStoreSwitchName)?.IsEnabled;
    if (retailMultiplePickUpOptionEnabled) {
        channelDeliveryOptionConfig = await getChannelDeliveryOptionConfigurationAsync({ callerContext: ctx });
    }
    /* Upgraded to 10.0.20 - END */
    const PickupDeliveryModeCode = channelConfiguration.PickupDeliveryModeCode;
    const EmailDeliveryModeCode = channelConfiguration.EmailDeliveryModeCode; // Upgraded to 10.0.16
    if (!cart || !channelConfiguration || !products || products.length === 0) {
        ctx.trace('[getAvailabilitiesForCartLineItems] Not able to get cart OR channelConfiguration or no products in cart');
        return <IProductInventoryInformation[]>[];
    }

    if (cart && cart.Id && cart.CartLines && cart.CartLines.length > 0 && channelConfiguration) {
        for (const cartLine of cart.CartLines) {
            if (
                cartLine.DeliveryMode &&
                cartLine.DeliveryMode !== '' &&
                getDeliveryMode(cartLine, retailMultiplePickUpOptionEnabled, channelDeliveryOptionConfig, PickupDeliveryModeCode)
            ) {
                bopisItems.push(cartLine);
            } else if (cartLine.DeliveryMode !== EmailDeliveryModeCode) {
                shippingItems.push(cartLine);
            }
        }
    }

    if (shippingItems && shippingItems.length > 0) {
        const masterProductIds: number[][] = [];
        
        let productIds = shippingItems.map(x => x.ProductId!);
        productIds = unique(productIds);
        const simpleProductData = await getSimpleProducts(productIds, input, ctx);
        productIds = simpleProductData.map(sp => {
            if(sp.MasterProductId && sp.MasterProductId !== sp.RecordId) {
                masterProductIds.push([sp.MasterProductId, sp.RecordId]);
                return sp.MasterProductId;
            } else {
                return sp.RecordId;
            }
        });

        /* VSI Customization - START - 21/12/20 */
        const {
            requestContext: {
                app: {
                    config: { maxQuantityForCartLineItem, inStockCode, inStockLabel }
                }
            }
        } = ctx;

        let vendorShippedProducts: number[] = []; // List of vendorShip products
        let dobbiesPlantsProducts: number[] = []; // List of Dobbies plants products
        let dobbiesOtherProducts: number[] = []; // List of Dobbies all products except plants

        if (productIds.length > 0) {
            // First divide products into 2 categories to not call getEstimatedAvailabilityAsync for vendorShip items but just for dobbies products
            const productsByVendor = await _getProductsByTypes(ctx, productIds, masterProductIds);
            vendorShippedProducts = productsByVendor.vendorShippedProducts;
            dobbiesPlantsProducts = productsByVendor.plantsProducts;
            dobbiesOtherProducts = productsByVendor.dobbiesOtherProducts;
        }

        const shippingProductAvailabilites = await getShippingProductAvailabilities(ctx, dobbiesOtherProducts, dobbiesPlantsProducts);

        if (
            shippingProductAvailabilites &&
            shippingProductAvailabilites.ProductWarehouseInventoryAvailabilities &&
            shippingProductAvailabilites.ProductWarehouseInventoryAvailabilities.length > 0
        ) {
            productAvailabilities = mapProductInventoryInformation(
                ctx,
                shippingProductAvailabilites.ProductWarehouseInventoryAvailabilities
            );
        }

        // Set maxQuantityForCartLineItem as limit for vendorShip items
        vendorShippedProducts.map(productId => {
            // Now map it into IProductInventoryInformation type
            productAvailabilities.push({
                IsProductAvailable: true,
                ProductAvailableQuantity: { ProductId: productId, AvailableQuantity: maxQuantityForCartLineItem },
                StockLevelCode: inStockCode,
                StockLevelLabel: inStockLabel,
                InventLocationId: channelConfiguration.InventLocation
            });
        });
        /* VSI Customization - END */
    }
    // Add bopis item availability
    const allProductAvailabilities = await _getBopisItemAvailability(ctx, productAvailabilities, bopisItems);

    if (allProductAvailabilities && allProductAvailabilities.length > 0) {
        return allProductAvailabilities;
    }

    ctx.trace('[getAvailabilitiesForCartLineItems] unable to get availabilites for product');
    return <IProductInventoryInformation[]>[];
}

/**
 * The function that returns the sum of all warehousees' PhysicalAvailable for plants Category
 */
const getAccumulatedTotalAvailable = (
    ctx: IActionContext,
    ProductWarehouseInventoryAvailabilities: ProductWarehouseInventoryAvailability[],
    productId: number
) => {
    // Just check inventory in warehouseIdsForFulfillment
    const {
        requestContext: {
            app: {
                config: { warehouseIdsForFulfillment }
            }
        }
    } = ctx;

    let accumulatedProductPhysicalAvailable = 0;
    const fulfillmentStores: string[] | undefined = warehouseIdsForFulfillment; // ['S011', 'S040']

    // First find product's warehouse Info in ProductWarehouseInventoryAvailabilities and then calculates their inventory's sum
    ProductWarehouseInventoryAvailabilities.filter(availability => {
        // First check if it is one of the warehouseIdsForFulfillment, only then add inventory
        const isFulfillmentStore =
            fulfillmentStores &&
            fulfillmentStores.find(store => {
                return availability.InventLocationId && availability.InventLocationId.toLowerCase() === store.toLowerCase();
            });

        return isFulfillmentStore && availability && availability.ProductId === productId;
    }).map(availability => {
        if (availability.PhysicalAvailable) {
            accumulatedProductPhysicalAvailable += availability.PhysicalAvailable;
        }
    });

    // Now just create an object with this product availability info and returns it
    return accumulatedProductPhysicalAvailable;
};

/* Following function checks inventory for bopis items */
const _getBopisItemAvailability = async (
    ctx: IActionContext,
    productAvailabilities: IProductInventoryInformation[],
    bopisItems: CartLine[]
): Promise<IProductInventoryInformation[]> => {
    if (bopisItems && bopisItems.length > 0) {
        for (const bopisItem of bopisItems) {
            const productWarehouse: ProductWarehouse = {
                ProductId: bopisItem.ProductId,
                InventLocationId: bopisItem.WarehouseId
            };

            if (ctx.requestContext.channel && ctx.requestContext.channel.InventLocationDataAreaId) {
                productWarehouse.DataAreaId = ctx.requestContext.channel.InventLocationDataAreaId;
            }
            const getProductWarehouseAvail = await getEstimatedProductWarehouseAvailabilityAsync(
                { callerContext: ctx, bypassCache: 'get', queryResultSettings: {} },
                [productWarehouse]
            );
            if (
                getProductWarehouseAvail &&
                getProductWarehouseAvail.ProductWarehouseInventoryAvailabilities &&
                getProductWarehouseAvail.ProductWarehouseInventoryAvailabilities.length > 0
            ) {
                const productWarehouseMapping = mapProductInventoryInformation(
                    ctx,
                    getProductWarehouseAvail && getProductWarehouseAvail.ProductWarehouseInventoryAvailabilities
                );
                if (productWarehouseMapping && productWarehouseMapping.length) {
                    for (const item of productWarehouseMapping) {
                        productAvailabilities.push(item);
                    }
                }
            }
        }
        return productAvailabilities;
    }
    return productAvailabilities;
};

/* Following function divides products into 3 categories 1. VendorShipProducts 2. DobbiesPlantProducts 3. DobbiesAllOtherProducts */
const _getProductsByTypes = async (
    ctx: IActionContext,
    productIds: number[],
    masterProductIds?: number[][]
): Promise<{
    vendorShippedProducts: number[];
    plantsProducts: number[];
    dobbiesOtherProducts: number[];
}> => {
    const vendorShippedProducts: number[] = [];
    const plantsProducts: number[] = [];
    const dobbiesOtherProducts: number[] = [];

    const callerContext = ctx;
    const {
        requestContext: {
            apiSettings: { channelId, catalogId },
            app: {
                config: { fulfillmentAttributeName, fulfillmentAttributeTextValue }
            }
        }
    } = ctx;
    const searchCriteriaInput: ProductSearchCriteria = {};
    searchCriteriaInput.Context = { ChannelId: channelId, CatalogId: catalogId };
    searchCriteriaInput.Ids = productIds;
    searchCriteriaInput.IncludeAttributes = true;
    // store product IDs in vendorShippedProducts array if product is vendor shipped
    const productsData = await searchByCriteriaAsync({ callerContext, queryResultSettings: {} }, searchCriteriaInput);
    productsData.map(product => {
        let { RecordId, AttributeValues } = product;
        if (masterProductIds) {
            for(const mp of masterProductIds) {
                if (mp[0] === RecordId) {
                    RecordId = mp[1];
                    product.RecordId = mp[1];
                }
            }
        }
        const attributes = AttributeValues;
        const isvendorShippedAttribute =
            attributes &&
            attributes.find(attribute => {
                const attributeName = attribute.Name && attribute.Name.trim().toLowerCase();
                return attributeName === 'isvendershipproduct';
            });
        const isVendorShipped =
            isvendorShippedAttribute &&
            isvendorShippedAttribute.TextValue &&
            isvendorShippedAttribute.TextValue.trim().toLowerCase() === 'yes';
        if (isVendorShipped) {
            vendorShippedProducts.push(RecordId);
        } else {
            const attributeName = fulfillmentAttributeName
                ? fulfillmentAttributeName
                      .toString()
                      .toLowerCase()
                      .trim()
                : 'fulfillmenttype';
            const attributeTextValue = fulfillmentAttributeTextValue
                ? fulfillmentAttributeTextValue
                      .toString()
                      .toLowerCase()
                      .trim()
                : 'plants';

            const fulfillmentTypeAttribute = attributes && attributes.find(attribute => attribute?.Name?.toLowerCase() === attributeName);
            const isPlantFulfillment = fulfillmentTypeAttribute && fulfillmentTypeAttribute.TextValue?.toLowerCase() === attributeTextValue;
            if (isPlantFulfillment) {
                plantsProducts.push(RecordId);
            } else {
                dobbiesOtherProducts.push(RecordId);
            }
        }
    });

    return {
        vendorShippedProducts: vendorShippedProducts,
        plantsProducts: plantsProducts,
        dobbiesOtherProducts: dobbiesOtherProducts
    };
};

/* Following function uses 2 separate call to check availability
 * For plants products (fulfillment category), get availability from all warehouses with filtered by FilterByChannelFulfillmentGroup
 * For all other products of dobbies, check inventory from default warehouse only */
const getShippingProductAvailabilities = async (
    ctx: IActionContext,
    dobbiesOtherProducts: number[],
    dobbiesPlantsProducts: number[]
): Promise<ProductWarehouseInventoryInformation> => {
    try {
        let shippingProductAvailabilites: ProductWarehouseInventoryInformation = {
            ProductWarehouseInventoryAvailabilities: [],
            ExtensionProperties: []
        };

        if (dobbiesOtherProducts && dobbiesOtherProducts.length && dobbiesOtherProducts.length > 0) {
            const uniqueProductList = unique(dobbiesOtherProducts);
            const otherProductAvailablities = await getEstimatedAvailabilityAsync(
                { callerContext: ctx, bypassCache: 'get' },
                { ProductIds: uniqueProductList, DefaultWarehouseOnly: true }
            );

            if (
                otherProductAvailablities.ProductWarehouseInventoryAvailabilities &&
                otherProductAvailablities.ProductWarehouseInventoryAvailabilities.length > 0
            ) {
                shippingProductAvailabilites = otherProductAvailablities;
            }
        }
        if (dobbiesPlantsProducts && dobbiesPlantsProducts.length && dobbiesPlantsProducts.length > 0) {
            // Now call for plants products
            const uniqueProductList = unique(dobbiesPlantsProducts);
            const plantsProductAvailablities = await getEstimatedAvailabilityAsync(
                { callerContext: ctx, bypassCache: 'get' },
                { ProductIds: uniqueProductList, DefaultWarehouseOnly: false, FilterByChannelFulfillmentGroup: true }
            );

            if (
                plantsProductAvailablities.ProductWarehouseInventoryAvailabilities &&
                plantsProductAvailablities.ProductWarehouseInventoryAvailabilities.length > 0
            ) {
                // Get ProductWarehouseInventoryAvailabilities as a sum of all warehouse inventory for this product and use it
                uniqueProductList.map(plantProduct => {
                    // Get accumulated inventory for each of product and map into an object of ProductWarehouseInventoryAvailability type
                    const accumulatedInventorySum = plantsProductAvailablities.ProductWarehouseInventoryAvailabilities
                        ? getAccumulatedTotalAvailable(
                              ctx,
                              plantsProductAvailablities.ProductWarehouseInventoryAvailabilities,
                              plantProduct
                          )
                        : 0;

                    // Now map it into ProductWarehouseInventoryAvailability type
                    const productInventoryAvailability = _mapToWarehouseInventoryAvailability(ctx, plantProduct, accumulatedInventorySum);

                    // Now add it into shippingProductAvailabilites
                    shippingProductAvailabilites.ProductWarehouseInventoryAvailabilities?.push(productInventoryAvailability);
                });
            }
        }

        return shippingProductAvailabilites;
    } catch (error) {
        ctx.telemetry.trace(error);
    }
    return {};
};

/**
 * The function returns an object of ProductWarehouseInventoryAvailability using
 */
const _mapToWarehouseInventoryAvailability = (
    ctx: IActionContext,
    productId: number,
    totalAvailable: number
): ProductWarehouseInventoryAvailability => {
    const {
        requestContext: {
            app: {
                config: { inStockCode, inStockLabel, outOfStockCode, outOfStockLabel }
            }
        }
    } = ctx;

    return {
        InventLocationId: 'ECOM',
        ProductId: productId,
        PhysicalInventory: 0,
        TotalAvailable: totalAvailable,
        TotalAvailableInventoryLevelLabel: totalAvailable > 0 ? inStockLabel : outOfStockLabel,
        TotalAvailableInventoryLevelCode: totalAvailable > 0 ? inStockCode : outOfStockCode,
        PhysicalAvailable: totalAvailable,
        PhysicalAvailableInventoryLevelLabel: totalAvailable > 0 ? inStockLabel : outOfStockLabel,
        PhysicalAvailableInventoryLevelCode: totalAvailable > 0 ? inStockCode : outOfStockCode
    };
};
/* VSI Customization - END */
export default createObservableDataAction({
    id: '@msdyn365-commerce-modules/retail-actions/get-availabilities-cartlines',
    action: <IAction<IProductInventoryInformation[]>>getAvailabilitiesForCartLineItems,
    input: createInput
});
